<template>
  <div
    class="category-card-pack-catalogue flex flex-col z-0"
    :style="`background-color: ${packRate.featured ? '' : colors.colorSurface}`"
  >
    <div
      v-if="packRate.featured"
      class="category-card-pack-catalogue__background min-h-full w-full"
      :style="`--custom_color:${colors.colorSurface}`"
    ></div>
    <div
      class="category-card-pack-catalogue__featured"
      v-if="packRate.featured"
    >
      <F2gIcon
        value="label"
        :strokeWidth="3"
        :stroke="colors.colorOnPrimary"
        :fill="colors.colorOnPrimary"
        :width="30"
        :height="30"
      />
    </div>

    <div
      v-if="category.errorDatesReserve"
      class="text-normal text-red-700 max-w-lg w-full pt-4 px-4 pb-4 category-card-pack-catalogue__error"
      style="display: flex; flex-direction: column"
      key="errorDates"
    >
      <div
        class="text-sm font-bold flex flex-row justify-center align-center errorText"
      >
        {{ category.txtErrorDatesReserve }}
      </div>
      <div class="flex flex-row justify-end align-end md:h-40 h-12">
        <v-btn
          class="w-full border-2 rounded-config30 text-xs px-1"
          :style="{
            backgroundColor: colors.colorPrimary,
            color: colors.colorOnPrimary,
          }"
          @click="$emit('changeDates')"
        >
          {{ $t("modifyDates") }}
        </v-btn>
      </div>
    </div>
    <!-- content -->
    <div
      class="max-h-full w-full flex flex-col category-card-pack-catalogue__content p-3"
      v-else
      key="content"
    >
      <!-- name-->
      <div
        class="xl:text-xl text-lg w-full font-bold flex flex-row-align-center align-center mb-4 text-md category-card-pack-catalogue__content"
        :style="{ color: colors.colorOnSurface }"
      >
        <F2gIcon
          key="info"
          class="mr-2 cursor-pointer"
          value="info"
          :strokeWidth="3"
          :width="18"
          :height="18"
          solid
          file="icons_solid.svg"
          :fill="$CONSTANTS.COLOUR.BLUE_1_F2G"
          @click="showDescription"
        >
        </F2gIcon>
        <span>{{ packRate.name }}</span>
      </div>
      <div
        class="w-full flex category-card-pack-catalogue__content"
        :class="{ 'flex-col': isSmallScreen, 'flex-row': !isSmallScreen }"
      >
        <v-flex
          :class="{
            xs12: isSmallScreen,
            xs7: !isSmallScreen,
            'category-card-pack-catalogue__content__data--shown': showDetails,
            'category-card-pack-catalogue__content__data--hidded ': !showDetails,
          }"
          class="category-card-pack-catalogue__content__data"
        >
          <div
            class="flex flex-col w-full category-card-pack-catalogue__content__data__items"
            :class="{
              'category-card-pack-catalogue__content__data__items--shown': showDetails,
              'category-card-pack-catalogue__content__data__items--hidded ': !showDetails,
            }"
            :id="`contentDataItemsCatalogue${index}`"
          >
            <!-- included km -->
            <div
              class="w-full flex flex-row-align-center align-center text-sm xl:text-md mb-2 font-bold category-card-pack-catalogue__content"
              :style="{ color: colors.colorOnSurface }"
            >
              <F2gIcon
                class="mr-2"
                value="checked-circle"
                :strokeWidth="3"
                :stroke="colors.colorOnPrimary"
                :width="16"
                :height="16"
                key="kmIncluded"
              />
              <span>{{
                packRate.kmIncluded > -1
                  ? `${packRate.kmIncluded} ${$t(
                      packRate.kmIncluded === 1 ? "kmIncluded" : "kmIncludeds"
                    )}`
                  : $t("unlimitedKm")
              }}</span>
            </div>
            <!-- fuelText -->
            <div
              class="w-full flex flex-row-align-center align-center text-sm xl:text-md mb-2 font-bold"
              :style="{ color: colors.colorOnSurface }"
            >
              <F2gIcon
                class="mr-2"
                value="checked-circle"
                :strokeWidth="3"
                :stroke="colors.colorOnPrimary"
                :width="16"
                :height="16"
                key="kmIncluded"
              />
              <span>{{
                packRate.fuelText[language]
                  ? packRate.fuelText[language]
                  : packRate.fuelText.en
              }}</span>
            </div>
            <!-- freeCancellation -->
            <div
              v-if="packRate.freeCancellation"
              class="w-full flex flex-row-align-center align-center text-sm xl:text-md mb-2 font-bold"
              :style="{ color: colors.colorOnSurface }"
            >
              <F2gIcon
                class="mr-2"
                value="checked-circle"
                :strokeWidth="3"
                :stroke="colors.colorOnPrimary"
                :width="16"
                :height="16"
              />
              <span>{{ $t("freeCancellation") }}</span>
            </div>
            <!-- franchise -->
            <div
              v-if="packRate.franchise"
              class="w-full flex flex-row-align-center align-center text-sm xl:text-md mb-2 font-bold"
              :style="{ color: colors.colorOnSurface }"
            >
              <F2gIcon
                class="mr-2"
                value="checked-circle"
                :strokeWidth="3"
                :stroke="colors.colorOnPrimary"
                :width="16"
                :height="16"
              />
              <span>{{ packRate.franchise }}</span>
            </div>
            <!-- characteristic -->
            <div
              class="w-full flex flex-row-align-center align-center text-sm xl:text-md mb-2 font-bold"
              :style="{ color: colors.colorOnSurface }"
              v-for="(characteristic, index) in packRate.characteristic"
              :key="index"
            >
              <F2gIcon
                class="mr-2"
                value="checked-circle"
                :strokeWidth="3"
                :stroke="colors.colorOnPrimary"
                :width="16"
                :height="16"
              />
              <span>{{ characteristic }}</span>
            </div>
          </div>
        </v-flex>
        <v-flex v-if="!isSmallScreen" xs5
          ><div
            class="w-full h-full"
            style="
              display: flex !important;
              flex-direction: column !important;
              justify-content: center !important;
              align-content: center !important;
            "
          >
            <!-- price day -->
            <div
              :style="{ color: colors.colorPrimary }"
              class="w-full mb-2 font-bold"
              style="display: flex; justify-content: end"
            >
              <span class="xl:text-2xl text-xl mr-1">{{
                BillingUtil.formatBillingValue(getPricePerTime, category.symbol)
              }}</span
              ><span class="xl:text-lg text-md pt-1">
                {{
                  isDays ? `/${$t("common.day")}` : `/${$t("common.hour")}`
                }}</span
              >
            </div>
            <!-- total -->
            <div
              class="w-full text-sm xl:text-md"
              style="display: flex; justify-content: end"
              :style="{ color: colors.colorPrimary }"
            >
              (
              <span class="mr-2">{{
                BillingUtil.formatBillingValue(
                  packRate.price ? packRate.price : 0,
                  category.symbol
                )
              }}</span>
              <span>{{ getTime }}</span> )
            </div>
          </div></v-flex
        >
        <div class="category-card-pack-catalogue__content__details">
          <transition name="slide-fade" mode="out-in">
            <div
              class="flex flex-row align-center cursor-pointer w-full min-h-8"
              v-if="showDetails"
              align-center
              key="show"
              @click="toggleShowDetails"
            >
              <span class="mr-1 text-xs">{{ $t("hideDetails") }}</span>
              <F2gIcon
                value="arrow-up"
                :strokeWidth="3"
                :stroke="colors.colorPrimary"
                :width="26"
                :height="26"
              />
            </div>
            <div
              class="flex flex-row align-center cursor-pointer w-full min-h-8"
              v-else
              align-center
              key="hide"
              @click="toggleShowDetails"
            >
              <span class="mr-1 text-xs">{{ $t("seeDetails") }}</span>
              <F2gIcon
                value="arrow-down"
                :strokeWidth="3"
                :stroke="colors.colorPrimary"
                :width="26"
                :height="26"
              />
            </div>
          </transition>
        </div>
      </div>
      <!-- price time -->
      <div class="xl:mt-2 mt-1" v-if="isSmallScreen">
        <div class="category-card-pack-catalogue__content__price mb-4">
          <div :style="{ color: colors.colorPrimary }" class="font-bold">
            <span class="xl:text-2xl text-xl mr-1">{{
              BillingUtil.formatBillingValue(getPricePerTime, category.symbol)
            }}</span
            ><span class="xl:text-lg text-md pt-1">
              {{
                isDays ? `/${$t("common.day")}` : `/${$t("common.hour")}`
              }}</span
            >
          </div>
          <div
            class="category-card-pack-catalogue__content__price__day font-bold text-xs"
            :style="{ color: colors.colorPrimary }"
          >
            (
            <span class="mr-2">{{
              BillingUtil.formatBillingValue(
                packRate.price ? packRate.price : 0,
                category.symbol
              )
            }}</span>
            <span>{{ getTime }}</span> )
          </div>
        </div>
      </div>
      <div class="w-full">
        <v-btn
          class="w-full border-2 rounded-config30"
          :style="{
            backgroundColor: colors.colorOnPrimary,
            color: colors.colorPrimary,
          }"
          @click="selectPack"
        >
          {{ $t("select") }}
        </v-btn>
      </div>
    </div>

    <!-- end content -->
  </div>
</template>

<script>
import BillingUtil from "@/flit2go-web-core/util/billingUtil.js";
import { mapGetters } from "vuex";

export default {
  name: "CategoryCardPackCatalogue",
  props: {
    packRate: { type: Object, required: true },
    category: { type: Object, required: true },
    index: { type: Number, required: true },
  },
  data() {
    return {
      BillingUtil,
      showDetails: false,
    };
  },
  computed: {
    ...mapGetters({
      colors: "configuration/getColors",
      language: "configuration/getUserLanguage",
    }),
    isSmallScreen() {
      return (
        this.$vuetify.breakpoint.name === "xs" ||
        this.$vuetify.breakpoint.name === "sm"
      );
    },
    isDays() {
      return this.packRate.timeType === "day";
    },
    getTime() {
      let time = `${this.packRate.time} `;

      if (this.isDays) {
        time += this.$t(
          this.packRate.time === 1 ? "common.day" : "common.days"
        );
      } else {
        time += this.$t(
          this.packRate.time === 1 ? "common.hour" : "common.hours"
        );
      }

      return time;
    },
    getPricePerTime() {
      let price;
      if (
        this.packRate.deferredPayment &&
        this.packRate.pricePerTimeDeferred &&
        this.packRate.deferredDiscount
      ) {
        price = this.packRate.pricePerTimeDeferred;
      } else if (this.packRate.pricePerTime) {
        price = this.packRate.pricePerTime;
      } else {
        price = 0;
      }
      return price;
    },
  },
  mounted() {
    if (window.innerWidth <= 960) {
      setTimeout(
        () => (
          (document.getElementById(
            `contentDataItemsCatalogue${this.index}`
          ).style.display = "none"),
          500
        )
      );
    }
  },
  methods: {
    showDescription() {
      this.$emit("showDescription", this.index);
    },
    selectPack() {
      this.$emit(
        "selected",
        this.packRate.deferredPayment && this.packRate.deferredDiscount
          ? true
          : false,
        this.packRate
      );
    },
    toggleShowDetails() {
      this.showDetails = !this.showDetails;
      if (this.showDetails) {
        document.getElementById(
          `contentDataItemsCatalogue${this.index}`
        ).style.display = "block";
      } else {
        setTimeout(
          () =>
            (document.getElementById(
              `contentDataItemsCatalogue${this.index}`
            ).style.display = "none"),
          1500
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.category-card-pack-catalogue {
  position: relative;
  z-index: 20;

  &__background {
    position: absolute;
    z-index: 24;
    background-color: rgba(var(--custom_color), 0.1);
  }

  &__content {
    position: relative;
    z-index: 30;

    &__details {
      display: none;
    }

    @media (max-width: 960px) {
      &__price {
        display: flex;
        flex-direction: row;
        align-content: center;

        &__day {
          flex-direction: row;
          justify-content: center;
          align-content: center;
          padding-top: 5px;
          padding-left: 5px;
        }
      }

      &__details {
        display: block;
      }

      &__data {
        overflow-y: hidden;
        &--shown {
          max-height: 8000px;
          transition: max-height 1s ease-in-out;
        }

        &--hidded {
          max-height: 0px !important;
          transition: max-height 1s ease-in-out;
        }

        &__items {
          &--shown {
            opacity: 1;
            transition: opacity 1s ease-in-out;
          }

          &--hidded {
            opacity: 0;
            transition: opacity 1s ease-in-out;
          }
        }
      }
    }
  }

  &__error {
    position: relative;
    z-index: 30;
  }

  &__featured {
    position: absolute;
    z-index: 50;
    top: 0;
    right: 0;
  }
}
</style>
